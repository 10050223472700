import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faUser, faBagShopping, faTrophy } from '@fortawesome/free-solid-svg-icons';

import NavbarComponent from './customComponents/NavbarComponent';
import HeaderComponent from './customComponents/HeaderComponent';
import FooterComponent from './customComponents/FooterComponent';
import AboutLanding from './customComponents/AboutLanding';
import CertificationsLanding from './customComponents/CertificationsLanding';
import ECommerceSection from './customComponents/ECommerceSection';
import Shop from './Shop';
import Biografia from './Biografia';
import ScrollToTop from './ScrollToTop';

import Aos from 'aos';
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={
          <>
            <NavbarComponent
              navLinks={[
                { id: 1, title: 'Home', href: '/', icon: <FontAwesomeIcon icon={faHouse} /> },
                { id: 2, title: 'About', href: '/#about', icon: <FontAwesomeIcon icon={faUser} /> },
                { id: 3, title: 'Certifications', href: '/#certifications', icon: <FontAwesomeIcon icon={faTrophy} /> },
                { id: 4, title: 'Shop', href: '/#shop', icon: <FontAwesomeIcon icon={faBagShopping} /> },
              ]}
            />
            <Home />
          </>} />
        <Route path="/about" element={<AboutLanding />} />
        <Route path="/certifications" element={<CertificationsLanding />} />
        <Route path="/shop" element={
          <>
            <NavbarComponent
              navLinks={[
                { id: 1, title: 'Home', href: '/', icon: <FontAwesomeIcon icon={faHouse} /> },
              ]}
            />
            <HeaderComponent
              content={[
                { title: 'Web services', subtitle: "By Matteo Migliarese", href: "/", cta: "Homepage" }
              ]}
            />
            <Shop />
          </>
          } />
        <Route path="/biografia" element={<Biografia />} />
      </Routes>
      <FooterComponent />
    </Router>
  );
}

const Home = () => (
  <>
    <HeaderComponent
        content={[
          { title: 'Matteo Migliarese', subtitle: "Web developer & Web designer", href: "/shop", cta: "Ottieni un preventivo" }
        ]}
    />
    <AboutLanding />
    <CertificationsLanding />
    <ECommerceSection />
  </>
);

export default App;