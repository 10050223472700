import React from 'react';
import { useState } from 'react';
import { Container, Row, Col, Form, Card, Button, Table, ListGroup, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faGift } from '@fortawesome/free-solid-svg-icons';

const Shop = () => {
    const bundle = [
        {
            title: "Basic",
            subtitle: "My Basic Website Bundle includes a set of tools and services to launch your professional website with the right foot:",
            price: 500,
        },
        {
            title: "Premium",
            subtitle: "My Premium Website Bundle includes a complete set of powerful tools and services to launch your professional website:",
            price: 750,
        }
    ];
    const basicFeatures = [
        {
            title: "Customisable website",
            description: "You will be able to integrate functions such as forms, galleries and much more into your website.",
            accent: "Page limit of 5"
        },
        {
            title: "Social Media Integration",
            description: "Let your followers know about your site and establish a better connection with them!"
        },
        {
            title: "SEO Optimization",
            description: "Basic SEO setup and optimization"
        },
        {
            title: "Multilingual Support",
            description: "Make your website available in multiple languages to reach a global audience."
        }
    ];
    const premiumFeatures = [
        {
            title: "Customisable website",
            description: "You will be able to integrate functions such as forms, galleries and much more into your website.",
            accent: "Page limit of 10"
        },
        {
            title: "Social Media Integration",
            description: "Let your followers know about your site and establish a better connection with them!"
        },
        {
            title: "SEO Optimization",
            description: "Basic SEO setup and optimization"
        },
        {
            title: "Multilingual Support",
            description: "Make your website available in multiple languages to reach a global audience."
        },
        {
            title: "Annual Maintenance",
            description: "Ensure your website runs smoothly with an annual maintenance service."
        }
    ];
    const dataBasic = [
        { percentage: '56%', description: 'Of the users choose this bundle' },
        { rating: '4.6/5', description: 'Is the average service rating' },
        { year: '2024', description: 'Latest efficiency standards guaranteed' },
    ];
    const dataPremium = [
        { percentage: '44%', description: 'Of the users choose this bundle' },
        { rating: '4.8/5', description: 'Is the average service rating' },
        { year: '2024', description: 'Latest efficiency standards guaranteed' },
    ];
    return (
        <>  
            <Container>
                <h6 className='display-6 fw-bolder mt-5 underline w-max wwrap'>Website Bundles</h6>
                <p className='lead mb-1'>Below you can find a list of convenient bundles ready for you to create your own site!</p>
            </Container>
            <ProductCard data={dataBasic} bundle={bundle} features={basicFeatures}></ProductCard>
            <Container>
                <hr></hr>
            </Container>
            <ProductCard data={dataPremium} bundle={bundle} features={premiumFeatures} inverse="true"></ProductCard>                
            <PriceSimulator></PriceSimulator>
        </>
    );

    function ProductCard({inverse, features, bundle, data}) {
        const bundleNumber = inverse == "true" ? 1 : 0;
        return (
            <Container>
                <Card className='my-3 border-0'>
                    <Card.Body className='rounded-3 p-0'>
                        <Row className={`mx-auto align-items-stretch ${inverse ? 'flex-row-reverse' : ''}`}>
                            <Col lg={9} className={`features d-flex flex-column justify-content-between p-0 ${inverse ? 'ps-lg-3' : 'pe-lg-3'} h-100`}>
                                <div className='bg-light-custom p-3 mb-lg-3 rounded-3'>
                                    <h3 className='mb-0 fw-bolder py-3'>
                                        {bundle[bundleNumber].title} Website Bundle <Badge bg="success">New</Badge>
                                    </h3>
                                    <Card.Text className='text-muted mb-2'>
                                        {bundle[bundleNumber].subtitle}
                                    </Card.Text>
                                    <div className='d-lg-none'>
                                        {features.map((feature, index) => (
                                            <div className="feature my-4" key={index}>
                                                <Card.Title>{feature.title}</Card.Title>
                                                <p className='text-muted'>
                                                    {feature.description}
                                                    {feature.accent && <span className='accent'> {feature.accent}</span>}
                                                </p>
                                            </div>
                                        ))}
                                        <h1 className="fw-light">Total price: <span className='accent'>{bundle[bundleNumber].price}€</span></h1>
                                        <p className='small text-muted mt-2'>Please note that the final price may vary.</p>
                                        <a href="https://wa.me/393396446669" className='d-inline-block w-max bg-dark text-light fw-bolder rounded-2 py-2 mb-2 mb-lg-0 px-3 text-decoration-none'>
                                            <FontAwesomeIcon icon={faWhatsapp} /> Finalize
                                        </a>
                                    </div>
                                </div>
                                <div className='rounded-3 bg-light-custom p-3 mb-3 d-none d-lg-block'>
                                    {features.map((feature, index) => (
                                        <div className="feature my-4" key={index}>
                                            <Card.Title>{feature.title}</Card.Title>
                                            <p className='text-muted'>
                                                {feature.description}
                                                {feature.accent && <span className='accent'> {feature.accent}</span>}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                                <div className='rounded-3 bg-light-custom p-3 d-none d-lg-block'>
                                    <h3 className="fw-light">Total price: <span className='accent'>{bundle[bundleNumber].price}€</span></h3>
                                    <p className='small text-muted mt-2'>Please note that the final price may vary.</p>
                                    <a href="https://wa.me/393396446669" className='d-inline-block w-max bg-dark text-light fw-bolder rounded-2 py-2 mb-2 mb-lg-0 px-3 text-decoration-none'>
                                        <FontAwesomeIcon icon={faWhatsapp} /> Finalize
                                    </a>
                                </div>        
                            </Col>
                            <Col lg={3} className='bg-light-custom rounded-3 p-3 p-lg-0 mt-3 mt-lg-0'>
                                <div className='d-flex flex-column justify-content-evenly p-3 h-max h-100'>
                                {data.map((item, index) => (
                                    <div key={index} className='text-center p-0'>
                                    <h6 className='display-6 accent'>
                                        {item.percentage || item.rating || item.year}
                                    </h6>
                                    <p className='text-muted small'>{item.description}</p>
                                    </div>
                                ))}
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
        );
    };

    function PriceSimulator() {
        const [options, setOptions] = useState({
            seoOptimization: false,
            socialMediaIntegration: false,
            eCommerce: false,
            blogManagement: false,
            annualMaintenance: false,
            controlPanel: false,
            multilingualSupport: false,
        });
    
        const prices = {
            seoOptimization: 50,
            socialMediaIntegration: 50,
            eCommerce: 200,
            blogManagement: 100,
            annualMaintenance: 150,
            controlPanel: 150,
            multilingualSupport: 200,
        };
    
        const handleChange = (event) => {
            const { name, checked } = event.target;
            setOptions({
                ...options,
                [name]: checked,
            });
        };
    
        const calculateTotal = () => {
            let total = 350;
            for (const [key, value] of Object.entries(options)) {
                if (value) {
                    total += prices[key];
                }
            }
            return total;
        };
    
        const CustomLabel = ({ text, price }) => (
            <span className='d-flex'>
                <Badge className='d-flex align-items-center ms-2 me-3' bg="accent">{price} €</Badge>
                <p className="m-0 fw-bold">{text}</p>
            </span>
        );
    
        return (
            <Container>
                <h6 className='display-6 fw-bolder mt-5 underline w-max wwrap'>Custom website</h6>
                <p className='lead mb-1'>Below you can make a projection for the price of a custom website.<br></br> I encourage you to check out the above bundles as they can sometimes be more convenient!</p>
                <Row className="justify-content-md-center">
                    <Col md={6}>
                        <Card className='border-0'>
                            <Card.Body className='px-0 pt-1'>
                                <Form>
                                    {[
                                        { name: "seoOptimization", label: "SEO Optimization", price: "50", description: "Improve your website visibility on search engines." },
                                        { name: "socialMediaIntegration", label: "Social Media Integration", price: "50", description: "Connect your website with social media platforms for increased interaction." },
                                        { name: "eCommerce", label: "E-commerce", price: "200", description: "Add an online store to sell your products or services." },
                                        { name: "blogManagement", label: "Blog Management", price: "100", description: "Create and manage a blog to share content and news." },
                                        { name: "annualMaintenance", label: "Annual Maintenance", price: "150", description: "Ensure your website runs smoothly with an annual maintenance service." },
                                        { name: "controlPanel", label: "Control Panel Creation", price: "150", description: "Implement a control panel for easy and intuitive website management." },
                                        { name: "multilingualSupport", label: "Multilingual Support", price: "200", description: "Make your website available in multiple languages to reach a global audience." }
                                    ].map(option => (
                                        <div key={option.name} className='p-2 my-2 rounded-3 bg-light-custom'>
                                            <Form.Check
                                                className='mt-2 mx-2'
                                                type="checkbox"
                                                label={<CustomLabel text={option.label} price={option.price} />}
                                                name={option.name}
                                                checked={options[option.name]}
                                                onChange={handleChange}
                                            />
                                            <hr></hr>
                                            <Form.Text className="mb-3 d-block text-muted mx-2">
                                                {option.description}
                                            </Form.Text>
                                        </div>
                                    ))}
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} className="d-none d-md-flex flex-column align-items-center justify-content-center">
                    <div>
                        
                    </div>
                        <div className="display-6">
                            Total price: <span className='accent'>{calculateTotal()}€</span>
                        </div>
                        <p className='small text-muted mt-2'>Please note that the final price may vary.</p>
                        <a href="https://wa.me/393396446669" className='d-block w-max bg-dark text-light fw-bolder rounded-2 text-light py-2 px-3 text-decoration-none'><FontAwesomeIcon icon={faWhatsapp} /> Finalize</a>
                    </Col>
                </Row>
                <Row className="d-md-none">
                    <Col className='d-flex align-items-center flex-column sticky-top'>
                        <hr />
                        <div className="text-center display-2">
                            Total Price: <span className='accent'>{calculateTotal()}€</span>
                        </div>
                        <p className='lead mt-2 text-center'>Please note that the final price may vary.</p>
                        <a href="https://wa.me/393396446669" className='d-block w-max bg-dark text-light fw-bolder rounded-2 text-light py-2 px-3 text-decoration-none'><FontAwesomeIcon icon={faWhatsapp} /> Finalize</a>
                    </Col>
                </Row>
            </Container>
        );
    };    
}

export default Shop;